import { useState, useEffect } from "react";
import { Form, Switch, Select, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingsAdmin,
  patchSettingsAdmin,
  clearSuccessEdit,
} from "../../store/settingsAdmin";
import {
  getSettingsAdminData,
  getError,
  getSettingsAdminLoading,
  getSuccessEdit,
} from "../../store/settingsAdmin/selectors";
import { getStudioData } from "../../store/studios/selectors";
import { getStaffDropDown } from "../../store/staff";
import { getStaffDropDownSelector } from "../../store/staff/selectors";
import { throwFormError } from "../../utils/utils";
import displayErrors from "../../utils/displayErrors";
import styles from "./styles.module.css";

export const SettingsAdminPage = () => {
  const [formSettingsAdmin] = Form.useForm();
  const dispatch = useDispatch();
  const studio = useSelector(getStudioData);
  const settingsAdminData = useSelector(getSettingsAdminData);
  const error = useSelector(getError);
  const success = useSelector(getSuccessEdit);
  const isLoading = useSelector(getSettingsAdminLoading);
  const [width, setWidth] = useState(window.innerWidth);
  const masterList = useSelector(getStaffDropDownSelector);
  const studio_y = JSON.parse(sessionStorage.getItem("studio"));
  const [recWithoutClient, setRecWithoutClient] = useState(false);
  const [recDeletedWithPay, setRecDeletedWithPay] = useState(false);
  const [recDeletedWihClientCome, setRecDeletedWihClientCome] = useState(false);
  const [recNoPayClientCome, setRecNoPayClientCome] = useState(false);
  const [recPayClientNotCome, setRecPayClientNotCome] = useState(false);
  const [recNoServices, setRecNoServices] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    dispatch(clearSuccessEdit());
    dispatch(
      getSettingsAdmin({
        studio_y_id: studio?.y_id || studio_y?.y_id,
      })
    );

    dispatch(getStaffDropDown(studio?.y_id || studio_y?.y_id));
  }, [studio?.y_id, studio_y?.y_id, success]);

  useEffect(() => {
    if (settingsAdminData) {
      formSettingsAdmin.setFields([
        {
          name: "master",
          value:
            settingsAdminData?.uncheck_staff?.map((item) => {
              return {
                value: item?.id,
                label: item?.y_name,
              };
            }) || "",
        },
      ]);
      setRecWithoutClient(settingsAdminData?.rec_without_client);
      setRecDeletedWithPay(settingsAdminData?.rec_deleted_with_pay);
      setRecDeletedWihClientCome(
        settingsAdminData?.rec_deleted_wih_client_come
      );
      setRecNoPayClientCome(settingsAdminData?.rec_no_pay_client_come);
      setRecPayClientNotCome(settingsAdminData?.rec_pay_client_not_come);
      setRecNoServices(settingsAdminData?.rec_no_services);
    }
  }, [formSettingsAdmin, settingsAdminData, success]);

  return (
    <div
      style={{
        display: "flex",
        gap: width > 640 ? 12 : 9,
        position: "relative",
        paddingBottom: width > 640 ? 0 : 16,
      }}
    >
      <div className={styles.container}>
        <div className={styles.containerTitle}>
          <p className={styles.title}>Ошибки администратора</p>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 150,
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Form
            form={formSettingsAdmin}
            className={styles.containerButton}
            onFinish={(data) => {
              if (data.master && Array.isArray(data.master)) {
                data.uncheck_staff = data.master.map(
                  (item) => item.value || item
                );
              } else {
                data.uncheck_staff = data.master;
              }
              data.rec_without_client = recWithoutClient;
              data.rec_deleted_with_pay = recDeletedWithPay;
              data.rec_deleted_wih_client_come = recDeletedWihClientCome;
              data.rec_no_pay_client_come = recNoPayClientCome;
              data.rec_pay_client_not_come = recPayClientNotCome;
              data.rec_no_services = recNoServices;
              delete data.master;

              dispatch(
                patchSettingsAdmin({
                  studio_y_id: studio?.y_id || studio_y?.y_id,
                  params: data,
                })
              ).then((state) => {
                if (state?.payload?.errors) {
                  throwFormError(formSettingsAdmin, state?.payload);
                }
              });
            }}
          >
            {error && displayErrors(error)}
            <p className={styles.text_info}>
              Выберите из списка ниже, что является ошибкой администратора. Эта
              информация будет отображаться в ежедневной сводке.
            </p>
            <div className={styles.containerSwitch}>
              <Form.Item className={styles.switch} name="rec_without_client">
                <Switch
                  checked={recWithoutClient}
                  onChange={setRecWithoutClient}
                />
                <span className={styles.text}>
                  Записи, в которых не выбран клиент
                </span>
              </Form.Item>
              <Form.Item className={styles.switch} name="rec_deleted_with_pay">
                <Switch
                  checked={recDeletedWithPay}
                  onChange={setRecDeletedWithPay}
                />
                <span className={styles.text}>
                  Удаленные записи, в которых есть оплата
                </span>
              </Form.Item>
              <Form.Item
                className={styles.switch}
                name="rec_deleted_wih_client_come"
              >
                <Switch
                  checked={recDeletedWihClientCome}
                  onChange={setRecDeletedWihClientCome}
                />
                <span className={styles.text}>
                  Удаленные записи со статусом “Клиент пришел”
                </span>
              </Form.Item>
              <Form.Item
                className={styles.switch}
                name="rec_no_pay_client_come"
              >
                <Switch
                  checked={recNoPayClientCome}
                  onChange={setRecNoPayClientCome}
                />
                <span className={styles.text}>
                  Записи со статусом “Клиент пришел”, но без оплаты
                </span>
              </Form.Item>
              <Form.Item
                className={styles.switch}
                name="rec_pay_client_not_come"
              >
                <Switch
                  checked={recPayClientNotCome}
                  onChange={setRecPayClientNotCome}
                />
                <span className={styles.text}>
                  Оплаченные записи со статусом “Клиент пришел”
                </span>
              </Form.Item>
              <Form.Item className={styles.switch} name="rec_no_services">
                <Switch checked={recNoServices} onChange={setRecNoServices} />
                <span className={styles.text}>Записи без выбранных услуг</span>
              </Form.Item>
            </div>
            <p className={styles.text_info}>
              Добавьте сотрудников, которых необходимо исключить при проверке
              ошибок. Например, это может быть сотрудник для листа ожидания.
            </p>
            <Form.Item name="master" style={{ marginTop: 11 }}>
              <Select
                style={{ borderRadius: 2 }}
                options={masterList?.results?.map((option) => {
                  return {
                    value: option.id,
                    label: option.y_name,
                  };
                })}
                optionFilterProp="label"
                mode="multiple"
                showSearch={true}
                allowClear={true}
                // value={selectedType}
                // onChange={setSelectedType}
                placeholder="Выберите сотрудника"
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
              >
                <span className={styles.button_text}>Сохранить</span>
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};
