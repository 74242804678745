import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../login";

const checkFilters = (filters, filtersArray, cursor = false, limit = false) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getCalculation = createAsyncThunk(
  "getCalculation",
  async (
    { studio_y_id, filters, filtersArray, limit, cursor, pagination },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/studio_salary_paid/studio/${studio_y_id}/calculate_payments/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

export const postCalculation = createAsyncThunk(
  "postCalculation",
  async ({ studio_y_id, payments }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/studio_salary_paid/studio/${studio_y_id}/salary_paid/`,
        payments
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  calculation: null,
  openCalculation: false,
  nextPage: null,
  success: {
    success: false,
    create: false,
  },
  errors: {
    error: null,
    create: null,
  },
  loaders: {
    common: false,
  },
};

const calculationSlice = createSlice({
  name: "calculation",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    setOpenCalculation: (state) => {
      state.openCalculation = !state.openCalculation;
    },
    clearSuccess: (state) => {
      state.success.success = false;
    },
    clearErrorCreate: (state) => {
      state.errors.create = null;
    },
    clearSuccessCreate: (state) => {
      state.success.create = false;
    },
  },

  extraReducers: (builder) => {
    // getCalculation
    builder.addCase(getCalculation.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
      state.nextPage = null;
    });
    builder.addCase(getCalculation.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.calculation = payload;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getCalculation.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
    // postCalculation
    builder.addCase(postCalculation.pending, (state) => {
      state.loaders.common = true;
      state.errors.create = null;
    });
    builder.addCase(postCalculation.fulfilled, (state) => {
      state.loaders.common = false;
      state.success.create = true;
    });
    builder.addCase(postCalculation.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.create = action?.payload;
    });
  },
});
export const {
  clearError,
  setOpenCalculation,
  clearErrorCreate,
  clearSuccess,
  clearSuccessCreate,
} = calculationSlice.actions;
export default calculationSlice.reducer;
