import dayjs from "dayjs";
import { Radio, Divider, Descriptions } from "antd";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";

const dateFormat = "YYYY-MM-DD";

export const isInclude = (isInclude) => {
  return (
    <span
      style={{
        margin: "-13px 0",
        position: "absolute",

        color: isInclude ? "#219653" : "#EB5757",
      }}
    >
      {isInclude ? "Включить:" : "Исключить:"}
    </span>
  );
};

export const convertToLocalDate = (date) => {
  if (date) {
    return new Date(date).toLocaleString("ru-RU").replace(", ", " ");
  } else return null;
};

export const getDaysWord = (days) => {
  if (days === 1) {
    return "день";
  } else if (days > 1 && days < 5) {
    return "дня";
  } else {
    return "дней";
  }
};

export const resetDateFilterBefore = (value) => {
  if (!value) return null;
  const date = value?.split(" ")[0].split(".").reverse().join("-");
  // " " +
  // value?.split(" ")[1];
  return dayjs(date, dateFormat);
};

export const resetDateFilterAfter = (value) => {
  if (!value) return null;
  const date = value?.split(" ")[0]?.split(".")?.reverse().join("-");
  // " " +
  // value?.split(" ")[1];
  return dayjs(date, dateFormat);
};

export const convertDateToISO861 = (date) => {
  if (!date) return;
  let actualDateArr = date.split(" ");
  let actualDate = actualDateArr[0].split(".").reverse().join("-");
  return actualDate;
};

export const convertDate = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return inputDate ? `${year}-${month}-${day} ${hours}:${minutes}` : "";
};

export const convertDateISO = (inputDate) => {
  let dateObj = new Date(inputDate);

  let year = dateObj.getFullYear();
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let day = ("0" + dateObj.getDate()).slice(-2);
  let hours = ("0" + dateObj.getHours()).slice(-2);
  let minutes = ("0" + dateObj.getMinutes()).slice(-2);
  let seconds = ("0" + dateObj.getSeconds()).slice(-2);

  return inputDate
    ? `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
    : null;
};

export const convertDateCorrections = (inputDate) => {
  let dateObj = new Date(inputDate);

  let year = dateObj.getFullYear();
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let day = ("0" + dateObj.getDate()).slice(-2);

  return inputDate ? `${year}-${month}-${day}` : null;
};

export const throwFormError = (form, error) => {
  if (error?.type === "validation_error") {
    const formFields = Object.keys(form.getFieldsValue());

    error.errors.forEach((err) => {
      if (formFields.includes(err.attr)) {
        form.setFields([
          {
            name: err.attr,
            errors: [err.detail],
          },
        ]);
      }
    });
  }
};

export const selectDropdown = (menu, include, setInclude) => {
  return (
    <>
      <Radio.Group
        onChange={() => {
          setInclude(!include);
        }}
        value={include}
      >
        <div
          style={{
            padding: "0 10px",
            fontSize: 20,
          }}
        >
          <Radio value={true}>Включить</Radio>
          <Radio value={false}>Исключить</Radio>
        </div>
      </Radio.Group>
      <Divider style={{ margin: "8px 0" }} />
      {menu}
    </>
  );
};

export const updateFilters = (
  name,
  isInclude,
  values,
  setFiltersArray,
  filtersArray
) => {
  if (values !== null && values !== undefined) {
    const filter = {
      key: isInclude ? `${name}` : `${"exc__" + name}`,
      value: values,
      text: values,
      title: name,
    };
    setFiltersArray([
      ...filtersArray.filter((value) => !value.key.includes(name)),
      filter,
    ]);
  } else {
    setFiltersArray(filtersArray.filter((value) => !value.key.includes(name)));
  }
};

export const updateFiltersArr = (
  name,
  isInclude,
  values,
  setFiltersArray,
  filtersArray
) => {
  if (values.length > 0) {
    const filter = {
      key: isInclude ? `${name}` : `${"exc__" + name}`,
      value: values.join(","),
      text: values.join(","),
      title: name,
    };
    setFiltersArray([
      ...filtersArray.filter((value) => !value.key.includes(name)),
      filter,
    ]);
  } else {
    setFiltersArray(filtersArray?.filter((value) => !value.key.includes(name)));
  }
};

export function DescItem(label, content, key = label) {
  return (
    <Descriptions.Item key={key} label={label}>
      {content || "Нет данных"}
    </Descriptions.Item>
  );
}

export const searchName = (item, search) => {
  let bool = false;
  if (search) {
    if (item?.name?.toLowerCase().includes(search?.toLowerCase())) bool = true;
  } else bool = true;
  return bool;
};

export function translateWord(word) {
  const translations = {
    worker: "работник",
    administrator: "администратор",
    manager: "менеджер",
    owner: "собственник",
    system: "системный",
  };

  return translations[word] || word;
}

export function getColorAndText(number) {
  const COLORS = {
    1: "#fadb14",
    2: "#fadb14",
    3: "#fadb14",
    4: "#fa8c16",
    5: "#fa8c16",
    6: "#fa8c16",
    7: "#fa8c16",
    8: "#f5222d",
    9: "#f5222d",
    10: "#f5222d",
  };

  const color = COLORS[number];

  return <span style={{ color: color }}>{number}</span>;
}

export function formatDateString(dateString) {
  if (dateString) {
    const dateParts = dateString?.split("-");
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  } else {
    return "";
  }
}

export function reversFormatDateString(dateString) {
  if (dateString) {
    const dateParts = dateString?.split(".");
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  }
}

export function isDateWithinTwoWeeks(date) {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const differenceInTime = inputDate - currentDate;
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return Math.abs(differenceInDays) < 14;
}

export function generateRandomId(min = 1, max = 1000000) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const expandIcon = ({ expanded, onExpand, record }) => {
  const hasChildren = record.children && record.children.length > 0;

  return hasChildren ? (
    expanded ? (
      <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
    ) : (
      <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
    )
  ) : null;
};

export function dateToWeekdayAndWeekNumber(dateStr) {
  const [day, month, year] = dateStr.day.split("-").map(Number);
  const date = new Date(2000 + year, month - 1, day);

  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - startOfYear) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil((days + startOfYear.getDay() + 1) / 7);

  const weekdays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
  const weekdayAbbr = weekdays[date.getDay()];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: !dateStr?.weekend ? "black" : "#6E6D6D",
      }}
    >
      <span>{weekNumber}</span>
      <span>{weekdayAbbr}</span>
    </div>
  );
}
