import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import styles from "./styles.module.css";
import ArrowBottom from "../../images/arrowBottom.svg";
import IconUser from "../../images/iconUser.svg";
import IconAnalytics from "../../images/iconAnalytics.svg";
import IconCustomers from "../../images/iconCustomers.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Layout, Tooltip } from "antd";
import { MenuStudios } from "../MenuStudios";
import { getUserData } from "../../store/user/selectors";
import { getStudio } from "../../store/studios";
import Logo from "../../images/Logo.svg";
import { SidebarNetwork } from "../SidebarNetwork";
import { SidebarStudios } from "../SidebarStudios";
import { getNetworks } from "../../store/networks";

export const Sidebar = ({ pageId, openSidebar, width }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const [openMenu, setOpenMenu] = useState("");
  const [openStudios, setOpenStudios] = useState(false);
  const [openPersonnel, setOpenPersonnel] = useState(
    pageId === "personnal" && true
  );
  const [openAnalytics, setOpenAnalytics] = useState(
    pageId === "planning" && true
  );
  const [openSalaries, setOpenSalaries] = useState(
    (pageId === "corrections" || pageId === "correctionsNetwork") && true
  );
  const [openQuality, setOpenQuality] = useState(
    (pageId === "complaints" || pageId === "complaintsNetwork") && true
  );
  const [openFinance, setOpenFinance] = useState(pageId === "expenses" && true);

  const handleMouseEnter = (menu) => {
    setOpenMenu(menu);
  };

  const handleMouseLeave = () => {
    setOpenMenu("");
  };

  useEffect(() => {
    if (userData) {
      const studioData = JSON.parse(sessionStorage.getItem("studio"));
      const isStudio = studioData?.is_studio;
      const yId = studioData?.y_id || userData?.user_studios[0]?.y_id;

      if (!studioData) {
        sessionStorage.setItem(
          "studio",
          JSON.stringify(userData?.user_studios[0])
        );
        dispatch(getStudio(userData?.user_studios[0]?.y_id))
          .then(unwrapResult)
          .catch((error) => {
            if (
              error.data.errors.some((err) => err.code === "token_not_valid")
            ) {
              navigate("/login");
            }
          });
      } else if (isStudio) {
        dispatch(getStudio(yId))
          .then(unwrapResult)
          .catch((error) => {
            if (
              error.data.errors.some((err) => err.code === "token_not_valid")
            ) {
              navigate("/login");
            }
          });
      } else {
        dispatch(getNetworks(yId))
          .then(unwrapResult)
          .catch((error) => {
            if (
              error.data.errors.some((err) => err.code === "token_not_valid")
            ) {
              navigate("/login");
            }
          });
      }
    }
  }, [userData, JSON.parse(sessionStorage.getItem("studio"))?.y_id]);

  return (
    <Layout.Sider
      collapsible
      collapsed={!openSidebar}
      trigger={null}
      width={228}
      collapsedWidth={50}
    >
      <div
        style={{
          height: "100%",
          left: 0,
          top: 0,
          bottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F9F9F9",
          backgroundPosition: openSidebar
            ? "0px 0px, right 88px bottom -200px"
            : "0px 0px, center bottom -200px",
          backgroundSize: "100% 100%, auto 850px",
          backgroundRepeat: "repeat, no-repeat",
          transition: "background-position 200ms",
          padding: width > 640 ? "10px 0 0 0" : "6px 0 0 0",
        }}
      >
        {openSidebar ? (
          <div
            className={`${styles.container} ${
              openSidebar ? "" : styles.padding0
            }`}
            onClick={() => setOpenStudios(!openStudios)}
          >
            <div className={styles.containerStudio}>
              <img src={Logo} alt="Logo" />
              <div className={styles.containerInfo}>
                <Tooltip
                  color="#FFFFFF"
                  title={
                    <span style={{ color: "#4B4F55" }}>
                      {JSON.parse(sessionStorage.getItem("studio"))?.y_title ||
                        userData?.user_studios[0]?.y_title}
                    </span>
                  }
                >
                  <p className={styles.nameStudio}>
                    {JSON.parse(sessionStorage.getItem("studio"))?.y_title ||
                      userData?.user_studios[0]?.y_title}
                  </p>
                </Tooltip>
                <p className={styles.roleUser}>
                  {userData?.user_role?.role || "Пользователь"}
                </p>
              </div>
            </div>
            <img src={ArrowBottom} alt="Стрелка" className={styles.arrow} />
          </div>
        ) : (
          <div
            className={styles.containerLogo}
            onClick={() => setOpenStudios(!openStudios)}
          >
            <p className={styles.textLogo}>GG</p>
          </div>
        )}
        {openStudios && (
          <MenuStudios data={userData} setOpenStudios={setOpenStudios} />
        )}
        {JSON.parse(sessionStorage.getItem("studio"))?.is_studio ||
        !JSON.parse(sessionStorage.getItem("studio")) ? (
          <SidebarStudios
            pageId={pageId}
            openMenu={openMenu}
            openSidebar={openSidebar}
            openQuality={openQuality}
            setOpenQuality={setOpenQuality}
            openSalaries={openSalaries}
            setOpenPersonnel={setOpenPersonnel}
            openPersonnel={openPersonnel}
            setOpenSalaries={setOpenSalaries}
            openAnalytics={openAnalytics}
            setOpenAnalytics={setOpenAnalytics}
            handleMouseLeave={handleMouseLeave}
            handleMouseEnter={handleMouseEnter}
          />
        ) : (
          <SidebarNetwork
            pageId={pageId}
            openMenu={openMenu}
            openSidebar={openSidebar}
            openQuality={openQuality}
            setOpenQuality={setOpenQuality}
            openSalaries={openSalaries}
            setOpenPersonnel={setOpenPersonnel}
            openPersonnel={openPersonnel}
            setOpenSalaries={setOpenSalaries}
            openFinance={openFinance}
            setOpenFinance={setOpenFinance}
            handleMouseLeave={handleMouseLeave}
            handleMouseEnter={handleMouseEnter}
          />
        )}
      </div>
    </Layout.Sider>
  );
};
