import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  Input,
  Table,
  Spin,
  Avatar,
  Checkbox,
  Space,
  Button,
  Select,
  Badge,
} from "antd";
import filters from "../../images/filters.svg";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  isInclude,
  selectDropdown,
  convertToLocalDate,
  convertDateToISO861,
  expandIcon,
  formatDateString,
  reversFormatDateString,
  updateFilters,
  updateFiltersArr,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getStudioDropDown } from "../../store/networks";
import {
  getStudioData,
  getStudioDropDownSelector,
} from "../../store/networks/selectors";
import { getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getNetworkCalculation,
  postNetworkCalculation,
  clearError,
  clearErrorCreate,
  clearSuccessCreate,
} from "../../store/networkCalculation";
import {
  getCalculationData,
  getOpenCalculationNetworkSelector,
  getCalculationLoading,
  getError,
  getSuccess,
  getSuccessCreate,
} from "../../store/networkCalculation/selectors";
import { getPositionNetworkDropDown } from "../../store/staff";
import { getPositionNetworkDataSelector } from "../../store/staff/selectors";
import displayErrors from "../../utils/displayErrors";

export const CalculationNetwork = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoading = useSelector(getCalculationLoading);
  const errorStudio = useSelector(getErrorStudio);
  const calculationData = useSelector(getCalculationData);
  const isOpenCalculationNetwork = useSelector(
    getOpenCalculationNetworkSelector
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [fileSearch, setFileSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedStudio, setSelectedStudio] = useState([]);
  const [includePosition, setIncludePosition] = useState(true);
  const [includeStudio, setIncludeStudio] = useState(true);
  const [includeStatus, setIncludeStatus] = useState(true);
  const [openFiltersRow, setOpenFiltersRow] = useState(false);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  // const [openCalculationDetail, setOpenCalculationDetail] = useState(false);
  const studioList = useSelector(getStudioDropDownSelector);
  const positionList = useSelector(getPositionNetworkDataSelector);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const openInfo = useSelector(getOpenInfoSelector);
  const success = useSelector(getSuccess);
  const successCreate = useSelector(getSuccessCreate);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [sum, setSum] = useState(0);

  const columns = [
    createColumn(
      () => {
        const hasTrueRow = calculationData?.data?.some(
          (row) => !row?.payment && row?.total > 0
        );

        return (
          isOpenCalculationNetwork &&
          hasTrueRow && (
            <Checkbox
              checked={isCheckedAll}
              onChange={(e) => {
                e.stopPropagation();
                setIsCheckedAll(e.target.checked);
                if (e.target.checked) {
                  const visibleRows = calculationData?.data?.filter(
                    (row) => !row?.payment && row?.total > 0
                  );
                  setCheckedRows(visibleRows.map((row) => row));
                } else {
                  setCheckedRows([]);
                }
              }}
            />
          )
        );
      },
      "checkbox",
      (_, row) => {
        const isChecked = checkedRows.includes(row);

        return (
          isOpenCalculationNetwork &&
          !row?.payment &&
          row?.total > 0 && (
            <Checkbox
              checked={isChecked}
              onChange={(evt) => {
                evt.stopPropagation();
                let newCheckedRows;
                if (!isChecked) {
                  newCheckedRows = [...checkedRows, row];
                } else {
                  newCheckedRows = checkedRows.filter(
                    (item) => item?.employee?.id !== row?.employee?.id
                  );
                }
                setCheckedRows(newCheckedRows);

                const visibleRows = calculationData?.data?.filter(
                  (row) => !row?.payment && row?.total > 0
                );
                const isAllVisibleChecked =
                  newCheckedRows.length === visibleRows.length;

                setIsCheckedAll(isAllVisibleChecked);
              }}
            />
          )
        );
      },
      30,
      "center"
    ),
    createColumn(
      "Mастер",
      "employee",
      (_, row) => {
        return (
          row?.employee && (
            <div style={{ display: "flex", flexDirection: "column", gap: 11 }}>
              <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
                <Avatar
                  style={{ width: 25, minWidth: 25, height: 25 }}
                  src={row?.employee?.avatar}
                />
                <Space direction="vertical" style={{ gap: 0 }}>
                  <span style={{ textWrap: "nowrap" }}>
                    {row?.employee?.name}
                  </span>
                  <span
                    style={{
                      color: "#8B8B8B",
                      fontSize: 12,
                      lineHeight: "14.52px",
                      textWrap: "nowrap",
                    }}
                  >
                    {row?.employee?.position}
                  </span>
                </Space>
              </div>
            </div>
          )
        );
      },
      50
    ),
    createColumn(
      "Студия",
      "studio",
      (_, row) => {
        return (
          row?.master?.studio?.y_title || (
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  margin: 0,
                  color: "#8B8B8B",
                }}
              >
                Основная студия:
              </p>
              <p style={{ fontSize: 12, fontWeight: 400, margin: 0 }}>
                {row?.studio}
              </p>
            </div>
          )
        );
      },
      150
    ),
    createColumn(
      "Зарплата по Yclients",
      "y_client_salary_data",
      (_, row) => {
        return (
          (row?.y_client_salary_data?.salary || row?.y_client_salary_data) &&
          `${row?.y_client_salary_data?.salary || row?.y_client_salary_data} р.`
        );
      },
      100
    ),
    createColumn(
      "Шрафт",
      "total_penalty",
      (_, row) => {
        return row?.total_penalty && `${row?.total_penalty} р.`;
      },
      100
    ),
    createColumn(
      "Бонус",
      "total_bonus",
      (_, row) => {
        return row?.total_bonus && `${row?.total_bonus} р.`;
      },
      100
    ),
    createColumn(
      "Премия",
      "total_bounty",
      (_, row) => {
        return row?.total_bounty && `${row?.total_bounty} р.`;
      },
      100
    ),
    createColumn(
      "Выплата до зарплаты",
      "total_prepayment",
      (_, row) => {
        return row?.total_prepayment && `${row?.total_prepayment} р.`;
      },
      100
    ),
    createColumn(
      "Официальная зарплата",
      "total_official_salary",
      (_, row) => {
        return row?.total_official_salary && `${row?.total_official_salary} р.`;
      },
      100
    ),
    createColumn(
      "Выплаты",
      "payments",
      (_, row) => {
        if (row?.payments && row.payments.length > 0) {
          return (
            <Space direction="vertical" style={{ gap: 0 }}>
              {row.payments.map((item) => {
                return (
                  <Space
                    key={item.id}
                    direction="horizontal"
                    style={{ gap: 5 }}
                  >
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {formatDateString(item?.period_start_date)} -{" "}
                      {formatDateString(item?.period_end_date)}
                    </span>
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        textWrap: "nowrap",
                      }}
                    >
                      {item?.paid?.toLocaleString("ru-RU")} р.
                    </span>
                  </Space>
                );
              })}
            </Space>
          );
        } else {
          return row?.payment ? (
            <p style={{ margin: 0 }}>Есть</p>
          ) : (
            <p style={{ margin: 0 }}>Нет</p>
          );
        }
      },
      150
    ),
    createColumn(
      "К выплате",
      "total_salary",
      (_, row) => {
        return (
          (row?.total_salary && `${row?.total_salary} р.`) ||
          (row?.total && (
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  margin: 0,
                  color: "#8B8B8B",
                }}
              >
                К выплате по сети:
              </p>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                {row?.total} р.
              </p>
            </div>
          ))
        );
      },
      150
    ),
    // createColumn(
    //   "К выплате по сети",
    //   "total",
    //   (_, row) => {
    //     return row?.total && `${row?.total} р.`;
    //   },
    //   100
    // ),
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const status = [
    { name: "Есть выплата", bol: true },
    { name: "Нет выплаты", bol: false },
  ];

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "master");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleDateString("ru-RU") + " - " + dateBeforeFilter
        );
      } else {
        setViewDataFilter(e?.$d.toLocaleDateString("ru-RU") + " - ");
      }
    } else {
      if (dateAfterFilter) {
        setViewDataFilter(dateBeforeFilter);
      }
      setDateAfterFilter(null);
    }
  };

  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter + " - " + e?.$d.toLocaleDateString("ru-RU")
        );
      } else {
        setViewDataFilter(" - " + e?.$d.toLocaleDateString("ru-RU"));
      }
    } else {
      if (dateBeforeFilter) {
        setViewDataFilter(dateAfterFilter);
      }
      setDateBeforeFilter(null);
    }
  };

  const fetchData = () => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    dispatch(clearError());
    dispatch(clearErrorCreate());

    if (dateAfterFilter && dateBeforeFilter) {
      dispatch(
        getNetworkCalculation({
          network_y_id:
            JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
            studioData?.y_id,
          filters: filters,
          filtersArray: filtersArray,
        })
      );
    }
  };

  const clearFilters = () => {
    setFileSearch("");
    setViewDataFilter(null);
    setDateBeforeFilter(null);
    setDateAfterFilter(null);
    setFiltersArray([]);
    setSelectedPosition([]);
    setSelectedStatus(null);
    setSelectedStudio([]);
    setIncludePosition(true);
    setIncludeStatus(true);
    setIncludeStudio(true);
  };

  useEffect(() => {
    if (!isOpenCalculationNetwork && checkedRows?.length > 0) {
      const payments = checkedRows.flatMap((row) => {
        return row.children.map((item) => {
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString();

          return {
            paid: item.y_client_salary_data.total_sum,
            period_start_date: reversFormatDateString(dateAfterFilter),
            period_end_date: reversFormatDateString(dateBeforeFilter),
            pay_date_time: formattedDate,
            related_corrections: item.related_corrections || [],
            master: item.master.id,
            studio: item.master.studio.id,
          };
        });
      });

      dispatch(
        postNetworkCalculation({
          network_y_id: studioData?.y_id,
          payments: payments,
        })
      );

      setCheckedRows([]);
      dispatch(clearSuccessCreate());
    }
  }, [isOpenCalculationNetwork]);

  useEffect(() => {
    updateFiltersArr(
      "position",
      includePosition,
      selectedPosition,
      setFiltersArray,
      filtersArray
    );
  }, [includePosition, selectedPosition]);

  useEffect(() => {
    updateFiltersArr(
      "studio",
      includeStudio,
      selectedStudio,
      setFiltersArray,
      filtersArray
    );
  }, [includeStudio, selectedStudio]);

  useEffect(() => {
    updateFilters(
      "payment",
      includeStatus,
      selectedStatus,
      setFiltersArray,
      filtersArray
    );
  }, [includeStatus, selectedStatus]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    dateAfterFilter,
    dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successCreate,
  ]);

  useEffect(() => {
    if (studioData) {
      dispatch(
        getStudioDropDown(
          JSON.parse(sessionStorage.getItem("studio"))?.y_id || studioData?.y_id
        )
      );
    }
  }, [JSON.parse(sessionStorage.getItem("studio"))?.y_id, studioData?.y_id]);

  useEffect(() => {
    if (studioData) {
      dispatch(
        getPositionNetworkDropDown(
          JSON.parse(sessionStorage.getItem("studio"))?.y_id || studioData?.y_id
        )
      );
    }
  }, [JSON.parse(sessionStorage.getItem("studio"))?.y_id, studioData?.y_id]);

  useEffect(() => {
    if (isOpenCalculationNetwork) {
      if (checkedRows?.length > 0) {
        const sumCheckedRows = checkedRows.reduce(
          (acc, row) => acc + row.total,
          0
        );
        setSum(sumCheckedRows.toLocaleString("ru-RU"));
      } else {
        setSum(0);
      }
    } else if (calculationData?.data) {
      const sumData = calculationData.data.reduce(
        (acc, item) => acc + item.total,
        0
      );
      setSum(sumData.toLocaleString("ru-RU"));
    } else {
      setSum(0);
    }
  }, [calculationData, isOpenCalculationNetwork, checkedRows]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth() - 1;
    const firstDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      previousMonth,
      1
    );
    const lastDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      previousMonth + 1,
      0
    );

    setDateAfterFilter(
      convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0]
    );
    setDateBeforeFilter(
      convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
    );
    setViewDataFilter(
      convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0] +
        " - " +
        convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
    );
  }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            // handleSetDateFilters={handleSetDateFilters}
            handleChangeDateBefore={handleChangeDateBefore}
            handleChangeDateAfter={handleChangeDateAfter}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={fileSearch}
                placeholder="Поиск по имени мастера"
                suffix={
                  <CloseOutlined
                    style={{
                      color: fileSearch ? "red" : "black",
                    }}
                    onClick={() => {
                      setFileSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setFileSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          <Space.Compact style={{ alignSelf: "flex-end" }} title="Фильтры">
            <Button
              onClick={() => {
                setOpenFiltersRow(!openFiltersRow);
              }}
              style={{
                borderRadius: "2px",
                width: width > 640 ? "151px" : "67px",
                gap: "20px",
              }}
              icon={
                <Badge count={filtersArray.length} size="small" color="blue">
                  <img
                    style={{
                      color: "#6679A9",
                    }}
                    alt="filters"
                    src={filters}
                  />
                </Badge>
              }
            >
              {width > 640 && "Фильтры"}
            </Button>
          </Space.Compact>

          {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>

        {openFiltersRow && (
          <>
            {width < 640 && (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingBottom: 25 }}
              >
                <Input
                  style={{ borderRadius: "2px" }}
                  prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                  value={fileSearch}
                  placeholder="Поиск по телефону, имени клиента, мастеру и типу переделки"
                  suffix={
                    <CloseOutlined
                      style={{
                        color: fileSearch ? "red" : "black",
                      }}
                      onClick={() => {
                        setFileSearch("");
                        setFiltersArray(
                          filtersArray.filter((v) => v.key !== "q")
                        );
                      }}
                    />
                  }
                  onChange={(evt) => {
                    setFileSearch(evt.target.value);
                    changeArrayFilters({
                      item: "",
                      key: "q",
                      value: evt.target.value,
                      text: evt.target.value,
                      title: "q",
                    });
                  }}
                />
              </Space>
            )}
            <div
              style={{
                display: width > 543 ? "flex" : "grid",
                rowGap: width > 640 ? 12 : 22,
                columnGap: width > 640 ? 12 : 7,
                gridTemplateColumns: width < 543 ? "repeat(2, 50%)" : "1fr",
                position: "relative",
                paddingBottom: width > 640 ? 20 : 10,
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStudio)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Название студии"
                  mode="multiple"
                  options={studioList?.studios?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_title,
                    };
                  })}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStudio, setIncludeStudio)
                  }
                  value={selectedStudio}
                  onChange={setSelectedStudio}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includePosition)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  mode="multiple"
                  placeholder="Должность"
                  style={{ width: "100%", textAlign: "left" }}
                  options={positionList?.map((option) => {
                    return {
                      value: option.pk,
                      label: option.y_title,
                    };
                  })}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includePosition, setIncludePosition)
                  }
                  value={selectedPosition}
                  onChange={setSelectedPosition}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStatus)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Выплаты"
                  value={selectedStatus}
                  onChange={setSelectedStatus}
                  options={status.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>
            </div>
          </>
        )}
      </>
      <div
        style={{
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 300,
        }}
      >
        Показано {calculationData?.data?.length || 0} из{" "}
        {calculationData?.data?.length || 0}
      </div>
      <div
        style={{
          maxHeight: !openFiltersRow
            ? "calc(100vh - 230px)"
            : "calc(100vh - 300px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.employee?.id}
          size="small"
          pagination={false}
          loading={isLoading && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={calculationData?.data || []}
          expandable={{
            expandIcon,
          }}
          // onRow={(record) => ({
          //   onClick: (evt) => {
          //     evt.stopPropagation();
          //     if (evt.target.type !== "checkbox") {
          //       setOpenCalculationDetail({
          //         open: true,
          //         record: record,
          //       });
          //     }
          //   },
          // })}
        />
      </div>
      {/* <div
        style={{
          fontSize: 16,
          fontWeight: 400,
          display: "flex",
          justifyContent: "end",
          gap: 5,
          marginTop: 5,
        }}
      >
        Итого к выплате:
        <span
          style={{
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          {sum}
        </span>
        р.
      </div> */}
      {/* {openCalculationDetail && (
        <CalculationDetailModal
          openDetails={openCalculationDetail}
          setOpenDetails={setOpenCalculationDetail}
          viewDataFilter={viewDataFilter}
        />
      )} */}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по расчетам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
