import { useState, useEffect } from "react";
import { Form, Switch, Select, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingsPlanning,
  patchSettingsPlanning,
  clearSuccessEdit,
} from "../../store/settingsPlanning";
import {
  getSettingsPlanningData,
  getError,
  getSettingsPlanningLoading,
  getSuccessEdit,
} from "../../store/settingsPlanning/selectors";
import { getStudioData } from "../../store/studios/selectors";
import { getStaffDropDown } from "../../store/staff";
import { getStaffDropDownSelector } from "../../store/staff/selectors";
import { throwFormError } from "../../utils/utils";
import displayErrors from "../../utils/displayErrors";
import styles from "./styles.module.css";

export const SettingsPlanningPage = () => {
  const [formSettingsPlanning] = Form.useForm();
  const dispatch = useDispatch();
  const studio = useSelector(getStudioData);
  const settingsPlanningData = useSelector(getSettingsPlanningData);
  const error = useSelector(getError);
  const success = useSelector(getSuccessEdit);
  const isLoading = useSelector(getSettingsPlanningLoading);
  const [width, setWidth] = useState(window.innerWidth);
  const masterList = useSelector(getStaffDropDownSelector);
  const studio_y = JSON.parse(sessionStorage.getItem("studio"));

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    dispatch(clearSuccessEdit());
    dispatch(
      getSettingsPlanning({
        studio_y_id: studio?.y_id || studio_y?.y_id,
      })
    );

    dispatch(getStaffDropDown(studio?.y_id || studio_y?.y_id));
  }, [studio?.y_id, studio_y?.y_id, success]);

  useEffect(() => {
    if (settingsPlanningData) {
      formSettingsPlanning.setFields([
        {
          name: "master",
          value:
            settingsPlanningData?.uncheck_staff?.map((item) => {
              return {
                value: item?.id,
                label: item?.y_name,
              };
            }) || "",
        },
      ]);
    }
  }, [formSettingsPlanning, settingsPlanningData, success]);

  return (
    <div
      style={{
        display: "flex",
        gap: width > 640 ? 12 : 9,
        position: "relative",
        paddingBottom: width > 640 ? 0 : 16,
      }}
    >
      <div className={styles.container}>
        <div className={styles.containerTitle}>
          <p className={styles.title}>Планирование</p>
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 150,
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Form
            form={formSettingsPlanning}
            className={styles.containerButton}
            onFinish={(data) => {
              if (data.master && Array.isArray(data.master)) {
                data.uncheck_staff = data.master.map(
                  (item) => item.value || item
                );
              } else {
                data.uncheck_staff = data.master;
              }
              delete data.master;

              dispatch(
                patchSettingsPlanning({
                  studio_y_id: studio?.y_id || studio_y?.y_id,
                  params: data,
                })
              ).then((state) => {
                if (state?.payload?.errors) {
                  throwFormError(formSettingsPlanning, state?.payload);
                }
              });
            }}
          >
            {error && displayErrors(error)}
            <p className={styles.text_info}>
              Добавьте сотрудников, которых необходимо исключить при проверке
              ошибок. Например, это может быть сотрудник для листа ожидания.
            </p>
            <Form.Item name="master" style={{ marginTop: 11 }}>
              <Select
                style={{ borderRadius: 2 }}
                options={masterList?.results?.map((option) => {
                  return {
                    value: option.id,
                    label: option.y_name,
                  };
                })}
                optionFilterProp="label"
                mode="multiple"
                showSearch={true}
                allowClear={true}
                placeholder="Выберите сотрудника"
              />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.button}
              >
                <span className={styles.button_text}>Сохранить</span>
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export const index = () => {
  return <div>index</div>;
};
