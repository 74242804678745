import React from "react";
import { Link } from "react-router-dom";
import IconQuality from "../../images/iconQuality.svg";
import IconArrowUp from "../../images/iconArrowUp.svg";
import IconArrowDown from "../../images/iconArrowDown.svg";
import IconSalary from "../../images/iconSalary.svg";
import IconPersonnel from "../../images/iconPersonnel.svg";
import IconFinance from "../../images/iconFinance.svg";
import styles from "./styles.module.css";

export const SidebarNetwork = ({
  pageId,
  openMenu,
  openSidebar,
  openQuality,
  setOpenQuality,
  openSalaries,
  setOpenPersonnel,
  openPersonnel,
  setOpenSalaries,
  openFinance,
  setOpenFinance,
  handleMouseLeave,
  handleMouseEnter,
}) => {
  return openSidebar ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "17px 0 0 12px",
      }}
    >
      <div key="personnel" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor:
              (openPersonnel || pageId === "staff_create") && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenPersonnel(!openPersonnel);
            setOpenSalaries(false);
            setOpenQuality(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={IconPersonnel}
                alt="Персонал"
                style={{ width: "22px", height: "22px" }}
              />
            </div>
            <span>Персонал</span>
          </div>
          {!openPersonnel ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {(openPersonnel || pageId === "staff_network_create") && (
          <div
            style={{
              padding: "9px 0px 23px 27px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor: openPersonnel && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                (pageId === "staff_network" ||
                  pageId === "staff_network_create") &&
                styles.customLinkSelected
              }`}
              to={`/staff_network/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Сетевые сотрудники
            </Link>
          </div>
        )}
      </div>
      <div key="quality" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openQuality && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenQuality(!openQuality);
            setOpenSalaries(false);
            setOpenFinance(false);
            setOpenPersonnel(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconQuality} alt="Качество" />
            <span>Качество</span>
          </div>
          {!openQuality ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openQuality && (
          <div
            style={{
              padding: "9px 0px 23px 27px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor: openQuality && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "complaintsNetwork" && styles.customLinkSelected
              }`}
              to={`/complaints_network/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Жалобы сети
            </Link>
          </div>
        )}
      </div>
      <div key="salaries" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openSalaries && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenSalaries(!openSalaries);
            setOpenQuality(false);
            setOpenFinance(false);
            setOpenPersonnel(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconSalary} alt="Зарплаты" />
            <span>Зарплаты</span>
          </div>
          {!openSalaries ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openSalaries && (
          <div
            style={{
              padding: "9px 0px 23px 27px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor: openSalaries && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "correctionsNetwork" && styles.customLinkSelected
              }`}
              to={`/corrections_network/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Коррекции сети
            </Link>
            <Link
              className={`${styles.customLink} ${
                pageId === "calculationNetwork" && styles.customLinkSelected
              }`}
              to={`/calculation_network/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Сетевой расчет
            </Link>
          </div>
        )}
      </div>
      <div key="finance" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openFinance && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenFinance(!openFinance);
            setOpenSalaries(false);
            setOpenQuality(false);
            setOpenPersonnel(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconFinance} alt="Финансы" />
            <span>Финансы</span>
          </div>
          {!openFinance ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openFinance && (
          <div
            style={{
              padding: "9px 0px 23px 27px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor: openFinance && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "expenses" && styles.customLinkSelected
              }`}
              to={`/expenses/${
                JSON.parse(sessionStorage.getItem("studio"))?.y_id
              }`}
            >
              Сетевые расходы
            </Link>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "10px 0 0",
      }}
    >
      <div
        key="personnel"
        onMouseEnter={() => handleMouseEnter("personnel")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "staff" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconPersonnel} alt="Персонал" />
          </div>
        </div>
        {openMenu === "personnel" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "personnel" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "personnel" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "staff_network" && styles.customLinkSelected
                }`}
                to={`/staff_network/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Сетевые сотрудники
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="quality"
        onMouseEnter={() => handleMouseEnter("quality")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "complaintsNetwork" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconQuality} alt="Качество" />
          </div>
        </div>
        {openMenu === "quality" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "quality" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "quality" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "complaintsNetwork" && styles.customLinkSelected
                }`}
                to={`/complaints_network/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Жалобы сети
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="salaries"
        onMouseEnter={() => handleMouseEnter("salaries")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "correctionsNetwork" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconSalary} alt="Зарплаты" />
          </div>
        </div>
        {openMenu === "salaries" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "salaries" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "salaries" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "correctionsNetwork" && styles.customLinkSelected
                }`}
                to={`/corrections_network/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Коррекции сети
              </Link>
              <Link
                className={`${styles.customLink} ${
                  pageId === "calculationNetwork" && styles.customLinkSelected
                }`}
                to={`/calculation_network/${
                  JSON.parse(sessionStorage.getItem("studio"))?.y_id
                }`}
              >
                Сетевой расчет
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="finance"
        onMouseEnter={() => handleMouseEnter("finance")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "expenses" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <img src={IconFinance} alt="Финансы" />
          </div>
        </div>
        {openMenu === "finance" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "finance" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "finance" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "expenses" && styles.customLinkSelected
                }`}
                to={`/v/${JSON.parse(sessionStorage.getItem("studio"))?.y_id}`}
              >
                Сетевые расходы
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
