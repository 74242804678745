import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Table, Spin, Button } from "antd";
import { DatePickerPopoverMount } from "../../components/DatePickerPopoverMount";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import {
  convertDateToISO861,
  convertToLocalDate,
  dateToWeekdayAndWeekNumber,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import { getStudioData, getErrorStudio } from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import { getPlanning, clearError } from "../../store/planning";
import {
  getPlanningData,
  getPlanningLoading,
  getError,
} from "../../store/planning/selectors";
import displayErrors from "../../utils/displayErrors";

export const PlanningPage = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const isLoading = useSelector(getPlanningLoading);
  const errorStudio = useSelector(getErrorStudio);
  const planningData = useSelector(getPlanningData);
  const [width, setWidth] = useState(window.innerWidth);
  const [filtersArray, setFiltersArray] = useState([]);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const openInfo = useSelector(getOpenInfoSelector);

  const columns = [
    createColumn(
      "День",
      "day",
      (_, row) => {
        return row && dateToWeekdayAndWeekNumber(row);
      },
      100
    ),
    createColumn(
      "Выполнение факт",
      "smart_plan_persent",
      (v) => {
        return (
          v && (
            <div
              style={{
                backgroundColor: `#${v?.smart_plan_persent_color}`,
                width: 53,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                fontWeight: 700,
              }}
            >
              {v}%
            </div>
          )
        );
      },
      100
    ),
    createColumn(
      "Выполнение прогноз",
      "smart_plan_exp",
      (v) => {
        return (
          v && (
            <div
              style={{
                backgroundColor: `#${v?.smart_plan_exp_color}`,
                width: 53,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 5,
                fontWeight: 700,
              }}
            >
              {v}%
            </div>
          )
        );
      },
      100
    ),
    createColumn(
      "Дневной план",
      "smart_plan",
      (v) => {
        return v && `${v} р.`;
      },
      100
    ),
    createColumn(
      "Дневной факт",
      "day_result",
      (v) => {
        return v && `${v} р.`;
      },
      100
    ),
    createColumn(
      "Прогноз",
      "day_forecast",
      (v) => {
        return `${v} р.`;
      },
      100
    ),
    createColumn(
      "Созданные записи",
      "expectations",
      (v) => {
        return `${v} р.`;
      },
      100
    ),
    createColumn(
      "Количество полных смен",
      "slots",
      (v) => {
        return v;
      },
      100
    ),
    createColumn(
      "Количество часов",
      "hours",
      (v) => {
        return v;
      },
      100
    ),
    createColumn(
      "Разбивка по специализациям",
      "specialisation",
      (v) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            {v.map((item) => {
              return (
                <div
                  style={{
                    backgroundColor: "#7ADA9D33",
                    padding: "4px 14px",
                    textWrap: "wrap",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 5,
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        );
      },
      200
    ),
    createColumn(
      "Прогноз",
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 22,
            }}
          >
            {v.map((item) => {
              return <span>{item.summ_expectation || 0} р.</span>;
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      "Факт",
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 18,
            }}
          >
            {v.map((item) => {
              return <span>{item.sum_fact || 0} р.</span>;
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      "Смены",
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 22,
            }}
          >
            {v.map((item) => {
              return <span>{item.sum_slots || 0}</span>;
            })}
          </div>
        );
      },
      100
    ),
    createColumn(
      "Часы",
      "specialisation",
      (v) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 22,
            }}
          >
            {v.map((item) => {
              return <span>{item.summ_hours}</span>;
            })}
          </div>
        );
      },
      100
    ),
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      const firstDayOfMonth = new Date(
        e?.$d.getFullYear(),
        e?.$d.getMonth(),
        1
      );
      setDateAfterFilter(firstDayOfMonth?.toLocaleDateString("ru-RU"));
      setViewDataFilter(firstDayOfMonth?.toLocaleDateString("ru-RU"));
    } else {
      setViewDataFilter(null);
      setDateAfterFilter(null);
    }
  };

  const fetchData = () => {
    let filters = false;

    if (dateAfterFilter) {
      filters = {
        mon_start_date: convertDateToISO861(dateAfterFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    dispatch(clearErrorStudio());
    dispatch(clearError());

    dispatch(
      getPlanning({
        studio_y_id:
          JSON.parse(sessionStorage.getItem("studio"))?.y_id ||
          studioData?.y_id,
        date: filters,
      })
    );
  };

  const clearFilters = () => {
    setViewDataFilter(null);
    setDateAfterFilter(null);
    setFiltersArray([]);
  };

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [dateAfterFilter, dateBeforeFilter, filtersArray, studioData]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = currentDate.setDate(1);

    setDateAfterFilter(
      convertToLocalDate(firstDayOfCurrentMonth).split(" ")[0]
    );
    setViewDataFilter(convertToLocalDate(firstDayOfCurrentMonth).split(" ")[0]);
  }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopoverMount
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            handleChangeDateAfter={handleChangeDateAfter}
          />

          {(dateAfterFilter || filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>
      </>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 600,
              gap: 5,
              marginBottom: 28,
            }}
          >
            <div style={{ display: "flex", gap: 10 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Факт:
                </div>
                <div
                  style={{
                    backgroundColor: "#B7E4C8",
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.fact}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Текущий:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.forecast_result_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.current_result
                    ? planningData?.summary?.current_result
                    : 0}
                  %
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  До плана:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.for_plan_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.for_plan
                    ? planningData?.summary?.for_plan
                    : 0}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  План:
                </div>
                <div
                  style={{
                    backgroundColor: "#B7E4C8",
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.plan}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Прогноз:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.forecast_result_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.forecast_result
                    ? planningData?.summary?.forecast_result
                    : 0}
                  %
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 9,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Обеспечено:
                </div>
                <div
                  style={{
                    backgroundColor: `#${planningData?.summary?.for_plan_forecast_color}`,
                    fontSize: 12,
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 53,
                    height: 22,
                    borderRadius: 5,
                  }}
                >
                  {planningData?.summary?.for_plan_forecast
                    ? planningData?.summary?.for_plan_forecast
                    : 0}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginBottom: 10,
              fontSize: 12,
              fontWeight: 300,
            }}
          >
            Показано {planningData?.data?.length || 0} из{" "}
            {planningData?.data?.length || 0}
          </div>
          <div
            style={{
              maxHeight: "calc(100vh - 300px)",
              width: "100%",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {(error || errorStudio) && displayErrors(error || errorStudio)}
            <Table
              columns={columns}
              rowKey={(obj) => obj.id}
              size="small"
              pagination={false}
              className={`${styles.table} ${styles.table_header}`}
              dataSource={planningData?.data || []}
              // onRow={() => ({
              //   onClick: (evt) => {
              //     evt.stopPropagation();
              //   },
              // })}
            />
          </div>
        </>
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по планированию"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};
